import { json, redirect } from '@remix-run/node';
import { Links, useLoaderData } from '@remix-run/react';

import { fetchWithoutAuth } from '~/callApi.server';
import LoginPanelWrapper, { links as loginPanelWrapperStyles } from '~/components/Auth/LoginPanelWrapper';
import endpoints from '~/constants/endpoints';
import handleIds from '~/constants/handleIds';
import routes from '~/constants/routes';
import { getCookie } from '~/cookies';
import { unfurlURLFromVanityData } from '~/helper/unfurl.server';
import { cookieSession } from '~/services/session.server';

import type { ActionFunction, LoaderFunction } from '@remix-run/node';
import type { ShouldRevalidateFunction } from '@remix-run/react';
import type { ApiResponse, DisplayGroup, Group, FlipError, RouteTyping, VanityData, VanityDataError } from 'types';
import type { ProfileImageId } from 'types/helperTypes';

export function links() {
  return [...loginPanelWrapperStyles()];
}

export const handle = {
  id: handleIds.Vanity,
};

export const shouldRevalidate: ShouldRevalidateFunction = ({ currentParams, nextParams }) => {
  // if the route is changing, we want to revalidate
  if (currentParams?.vanity !== nextParams?.vanity) return true;

  return false;
};

export const loader: LoaderFunction = async ({ params, request }) => {
  const gridTokenCookieInstance = cookieSession(request, 'GRID_TOKEN');
  const cookie = request.headers.get('Cookie');
  const searchParams = new URL(request.url).searchParams;

  const accessToken = getCookie('ACCESS_TOKEN', cookie);
  const ltiToken = searchParams.get('code');
  const usernameToken = searchParams.get('usernameToken') || searchParams.get('token');

  const group = (await fetchWithoutAuth({
    url: endpoints.VANITY_TOKENS_ID_URL_FUNC(params.vanity as string),
    method: 'GET',
    request,
    additionalHeaders: accessToken
      ? { Authorization: 'Bearer ' + accessToken }
      : usernameToken
        ? { 'x-authorization': 'grid_token ' + usernameToken }
        : undefined,
  })) as ApiResponse<VanityData, VanityData, FlipError & VanityDataError>;

  if (group.data && Object.keys(group.error).length === 0) {
    if (usernameToken) {
      const session = await gridTokenCookieInstance.getSession(request.headers.get('Cookie'));

      session.set('gridToken', usernameToken);
      session.set('baseURL', routes.GROUPS_ID_TOPICS_FUNC(group.data.grid.id));
      session.set('vanityToken', params.vanity);
      return redirect(unfurlURLFromVanityData(group.data), {
        headers: {
          'Set-Cookie': await gridTokenCookieInstance.commitSession(session),
        },
      });
    }
    return redirect(unfurlURLFromVanityData(group.data));
  }

  if (group.error?.status === 403 && accessToken && group.error.access_control === 'domain' && !ltiToken) {
    const displayGroup = (await fetchWithoutAuth({
      method: 'GET',
      //  the id here is actually a vanity token
      url: endpoints.EMBED_VANITY_TOKENS_ID_URL_FUNC(params.vanity as string),
      request,
      additionalHeaders: accessToken
        ? { Authorization: 'Bearer ' + accessToken }
        : usernameToken
          ? { 'x-authorization': 'grid_token ' + usernameToken }
          : undefined,
    })) as ApiResponse<DisplayGroup>;

    // If the user is a member and is not blocked, then redirect them to the group.
    // We need this so that if the lead accepts their request, the user can refresh the page and access the group.
    // Otherwise, the user would be stuck on the RTJ route.
    const functionalGroup =
      displayGroup?.data && 'resource_type' in displayGroup.data && displayGroup.data.resource_type !== 'grid'
        ? displayGroup.data.grid
        : displayGroup.data;

    if (functionalGroup?.membership?.role === 'member' && !functionalGroup?.membership?.blocked) {
      throw redirect(routes.GROUPS_ID_TOPICS_FUNC(functionalGroup.id));
    }

    if (functionalGroup?.membership?.role === 'invited') {
      const membershipGroup = (await fetchWithoutAuth({
        url: endpoints.GRIDS_ID_MEMBERS_ID_URL_FUNC(functionalGroup.id, functionalGroup.membership.id) + `?role=member`,
        method: 'PUT',
        request,
        additionalHeaders: accessToken
          ? { Authorization: 'Bearer ' + accessToken }
          : usernameToken
            ? { 'x-authorization': 'grid_token ' + usernameToken }
            : undefined,
      })) as ApiResponse<Group>;

      if (membershipGroup?.data?.membership?.role === 'member' && !membershipGroup?.data?.membership?.blocked) {
        throw redirect(routes.GROUPS_ID_TOPICS_FUNC(membershipGroup?.data.id));
      }
    }

    throw redirect(routes.VANITY_RTJ_FUNC(params.vanity as string));
  }

  const embed: ApiResponse<DisplayGroup> = await fetchWithoutAuth({
    request,
    url: endpoints.EMBED_VANITY_TOKENS_ID_URL_FUNC(params.vanity as string),
    method: 'GET',
  });

  // FOR LTI
  if (group.error?.status === 403 && Object.keys(embed.error).length === 0 && ltiToken) {
    const gridID = group.error.resource.id;

    const ltiGroup = await fetchWithoutAuth({
      request,
      url: endpoints.GRIDS_ID_TOKENS_URL_FUNC(gridID),
      method: 'POST',
      data: {
        lti_token: ltiToken,
      },
    });

    if (ltiGroup) {
      const session = await gridTokenCookieInstance.getSession(request.headers.get('Cookie'));

      session.set('gridToken', ltiGroup.data.token);
      session.set('baseURL', routes.GROUPS_ID_TOPICS_FUNC(ltiGroup.data.grid_id));
      session.set('ltiToken', ltiToken);
      session.set('vanityToken', params.vanity);

      throw redirect(
        embed.data.resource_type === 'topic'
          ? routes.GROUPS_ID_TOPICS_ID_RESPONSES_FUNC(ltiGroup.data.grid_id, embed.data.id)
          : routes.GROUPS_ID_TOPICS_FUNC(ltiGroup.data.grid_id),
        {
          headers: {
            'Set-Cookie': await gridTokenCookieInstance.commitSession(session),
          },
        },
      );
    }
  }

  if (Object.keys(embed.error).length === 0) {
    // assign each user a default profile ID #
    embed.data.users?.forEach(user => {
      const profileId = Math.floor(Math.random() * (4 - 1 + 1) + 1) as ProfileImageId; // number between 1-4
      user.profileImageId = profileId;
    });

    const vanityCookieInstance = cookieSession(request, 'VANITY');
    const session = await vanityCookieInstance.getSession(request.headers.get('Cookie'));
    session.set('vanityToken', params.vanity);
    const headers = new Headers();
    headers.set('Set-Cookie', await vanityCookieInstance.commitSession(session));

    return json(
      {
        data: { ...group.error, ...embed.data },
      },
      {
        headers,
      },
    );
  }

  // show the Not Found Page
  return json({ error: embed.error });
};

export const action: ActionFunction = async ({ request, params }) => {
  const formData = Object.fromEntries(await request.formData());
  const { enteredAsGuest, groupId, resourceType } = formData;
  const isUsernameMemberWithTopicResource = !enteredAsGuest && resourceType === 'topic';
  const resourceId = isUsernameMemberWithTopicResource && groupId ? groupId : formData.resourceID;

  const url =
    resourceType === 'grid' || isUsernameMemberWithTopicResource
      ? endpoints.GRIDS_ID_TOKENS_URL_FUNC(resourceId as string)
      : endpoints.TOPICS_ID_TOKENS_URL_FUNC(resourceId as string);

  const data = enteredAsGuest
    ? { password: formData.password }
    : // if data does not use student id, use student jwt for qr code logins
      formData.studentID
      ? { student_uid: formData.studentID }
      : { student_jwt: formData.student_jwt };

  const response = await fetchWithoutAuth({ url, method: 'POST', data, request });
  if (!response.fetchError && params.vanity) {
    const vanityData = (await fetchWithoutAuth({
      request,
      url: endpoints.VANITY_TOKENS_ID_URL_FUNC(params.vanity),
      method: 'GET',
      additionalHeaders: { 'x-authorization': 'grid_token ' + response.data.token },
    })) as ApiResponse<VanityData, VanityData, FlipError & VanityDataError>;

    if (!vanityData.fetchError && Object.keys(vanityData.data).length > 0) {
      const gridTokenCookieInstance = cookieSession(request, 'GRID_TOKEN');
      const session = await gridTokenCookieInstance.getSession(request.headers.get('Cookie'));

      session.set('gridToken', response.data.token);
      session.set('baseURL', routes.GROUPS_ID_TOPICS_FUNC(response.data.grid_id));
      session.set('vanityToken', params.vanity);
      if (enteredAsGuest) session.set('guest', true);
      if (session.get('ltiToken')) session.set('ltiToken', null);

      const headers = { 'Set-Cookie': await gridTokenCookieInstance.commitSession(session) };

      throw redirect(unfurlURLFromVanityData(vanityData.data), { headers });
    }
  }

  return json({ error: response.error, formData });
};

export default function VanityRoute() {
  const data = useLoaderData() as RouteTyping<'Vanity'>;

  return (
    <>
      <Links />
      {data && <LoginPanelWrapper data={data} />}
    </>
  );
}
