import { Anchor, Button, IcFluentGlobe24Regular, Modal, Smubble } from '@flipgrid/flipkit';
import { useMatches } from '@remix-run/react';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import externalLinks from '~/constants/externalLinks';
import Telemetry from '~/constants/telemetry';
import { telemetryAttributes } from '~/helper/helper';
import mobilePromptStyles from '~/styles/components/MobilePrompts/MobilePrompt.css';

import type { LinksFunction } from '@remix-run/node';
import type { TFunction } from 'i18next';
import type { Dispatch, SetStateAction } from 'react';
import type { UaDetectServer } from 'types/helperTypes';

type Props = {
  isTabletActive: boolean;
  linkToApp: string;
  setDisplayPrompt: Dispatch<SetStateAction<boolean>>;
  t: TFunction<'translation', undefined>;
};

export const links: LinksFunction = () => {
  return [{ rel: 'stylesheet', href: mobilePromptStyles }];
};

const Prompt = ({ isTabletActive, linkToApp, setDisplayPrompt, t }: Props) => {
  return (
    <div
      className={classNames('mobilePrompt', { ' -mobile': !isTabletActive })}
      // eslint-disable-next-line i18next/no-literal-string
      {...!isTabletActive && { 'aria-labelledby': 'mobilePrompt-useFlipPrompt' }}
      {...!isTabletActive && { 'aria-modal': 'true' }}
      {...!isTabletActive && { role: 'dialog' }}>

      <h3
        className={classNames('mobilePrompt__header', { ' -mobile': !isTabletActive }, 'fk-fontWeight__bold')}
        id="mobilePrompt-useFlipgridPrompt">

        {t('mobilePrompt.useFlipIn')}
      </h3>
      <div className="mobilePrompt__actions">
        <div className="mobilePrompt__action">
          <div className="mobilePrompt__logoBtnWrapper">
            <Smubble height={40} />
          </div>
          <div className="mobilePrompt__actionTitle" id="mobilePrompt-flipApp">
            {t('mobilePrompt.flipApp')}
          </div>
          <Anchor
            className="mobilePrompt__actionBtn"
            variant="button"
            aria-describedby="mobilePrompt-flipApp"
            size="36"
            to={linkToApp}
            data-testid="mobilePrompt__anchor__openStore"
            {...telemetryAttributes(Telemetry.MobilePromptOpenStore)}>

            {t('common.open')}
          </Anchor>
        </div>
        <div className="mobilePrompt__action">
          <div className="mobilePrompt__logoBtnWrapper--filled">
            <IcFluentGlobe24Regular height={40} width={40} fill="var(--fk-color__subtle)" />
          </div>
          <div className="mobilePrompt__actionTitle" id="mobilePrompt-continueBrowser">
            {t('mobilePrompt.browser')}
          </div>
          <Button
            className="mobilePrompt__actionBtn"
            onClick={() => setDisplayPrompt(false)}
            theme="tertiary"
            size="36"
            data-testid="mobilePrompt__button__openStore"
            aria-describedby="mobilePrompt-continueBrowser"
            {...telemetryAttributes(Telemetry.MobilePromptClosePrompt)}>

            {t('common.continue')}
          </Button>
        </div>
      </div>
    </div>);

};

const MobilePrompt = () => {
  const { t } = useTranslation();
  const [rootData] = useMatches();
  const ua: UaDetectServer = rootData.data.ua;
  const [displayPrompt, setDisplayPrompt] = useState(ua.mobile);
  const isTabletActive = ua.tablet;
  const linkToApp = ua.android ? externalLinks.GooglePlayStoreToFlip : externalLinks.ITunesStoreToFlip;

  if (!displayPrompt) return null;

  if (isTabletActive) {
    return (
      <Modal onClose={() => setDisplayPrompt(false)}>
        <Prompt isTabletActive={isTabletActive} linkToApp={linkToApp} setDisplayPrompt={setDisplayPrompt} t={t} />
      </Modal>);

  }

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div className="mobilePrompt__overlay" onClick={() => setDisplayPrompt(false)} />
      <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
        <Prompt isTabletActive={isTabletActive} linkToApp={linkToApp} setDisplayPrompt={setDisplayPrompt} t={t} />
      </FocusTrap>
    </>);

};

export default MobilePrompt;