import { Anchor, SmubbleWordmark } from '@flipgrid/flipkit';
import { Trans, useTranslation } from 'react-i18next';

import LoginPanel from './LoginPanel';
import NotFound from './NotFound';
import MobilePrompt from '../MobilePrompts/MobilePrompt';
import externalLinks from '~/constants/externalLinks';
import loginPanelIndexStyles from '~/styles/routes/index/loginPanelWrapperIndex.css';

import type { RouteTyping } from 'types';

export const links = () => [{ rel: 'stylesheet', href: loginPanelIndexStyles }];

type Props = {
  data: RouteTyping<'Vanity'>;
};

const LoginPanelWrapper = ({ data }: Props) => {
  const { t } = useTranslation();
  const errorState = !('data' in data);
  const displayGroup = errorState ? undefined : data.data;

  return (
    <main className="loginPanelWrapper">
      <div className="loginPanelWrapper__container">
        <SmubbleWordmark height={62} aria-label={t('common.flipLogo')} />

        <div className="loginPanelWrapper__card">
          {errorState ?
          <div className="layout__loginPanel-body">
              <NotFound />
            </div> :

          <LoginPanel displayGroup={data.data} />}

          <MobilePrompt />
        </div>

        {!errorState && displayGroup?.access_control &&
        <div className="loginPanelWrapper__legal">
            <Trans i18nKey="loginPanelWrapper.agreeToTerms">
              By continuing, you agree to
              <Anchor to={externalLinks.TermsOfUse} data-testid="loginPanelWrapper__a__tos">
                Flip's Terms of Use
              </Anchor>
              &nbsp;and&nbsp;
              <Anchor to={externalLinks.PrivacyPolicy} data-testid="loginPanelWrapper__a__privatePolicy">
                Privacy Policy
              </Anchor>
              .
            </Trans>
          </div>}

      </div>
    </main>);

};

export default LoginPanelWrapper;